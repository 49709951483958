.div-container-list {
    display: flex;
    align-items: center;
    padding: 10px;

    &.true {
        justify-content: none !important;
    }

    &.new {
        background-color: #EFF6EF;
    }
}

.div-circle-select {
    background-color: #EFF6EF;
    border-radius: 30px;
    cursor: pointer;
}

.div-icon-select {
    color: #00923F;
    margin: 5px;
    font-size: 15px;
}

.select-input-list {
    justify-content: space-between;
    border-radius: 30px !important;
    color: #000;
    min-height: 36px;
    height: 100%;
    align-items: center;
    display: flex;
    font-size: 15px;
    cursor: pointer;
    padding-right: 15px;

    &.all {
        padding-left: 50px;
    }




    &.selected {
        background-color: #EFF6EF;
        border-radius: 4px !important;
    }
}

.div-icon-list {
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    background-color: #EFF6EF !important;
    border-radius: 50% !important;

    .avatar {
        height: 30px;
        width: 30px;
        background-color: white;
    }

    .div-icon-select {
        margin: 8px;
        font-size: 14px;
    }
}

.div-text-list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.div-not {
    display: flex;
    justify-content: center;
}

.btn-not {
    font-size: 14px;


}

.List {
    &.is-dash {
        max-height: 110px !important;
    }

    &.not-dash {
        height: 480px !important;

        @media (min-width: 1920px) and (max-width: 1920px) {
            height: 35rem !important;
        }

        @media (min-width: 1440px) and (max-width: 1440px) {
            height: 460px !important;
        }

        @media (min-width: 1280px) and (max-width: 1439px) {
            height: 250px !important;
        }

        @media (min-width: 1024px) and (max-width: 1279px) {
            height: 280px !important;
        }

        @include tablet {
            height: 500px !important;
        }
    }
}

.div-no-data {
    display: flex;
    flex-direction: column;

    .text-no-data {
        display: flex;
        justify-content: center;
        font-family: Poppins, sans-serif;
    }

    .button {
        margin-top: 5px;
        align-self: center;
        height: 32px;
        padding: 0px 10px;
    }
}