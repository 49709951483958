//Theme Default
$primary-color: black;
$secondary-color: #96a2d4;
$error-color: #ed2d2d;
$success-color: #96a2d4;
$warning-color: #ffe5b3;
$gradient-primary-color: linear-gradient(
  112.38deg,
  #96a2d4 -2.36%,
  #96a2d4 93.39%
);
$gradient-secondary-color: linear-gradient(
  112.07deg,
  #004cdf 2.13%,
  #60d9f3 99.26%
);
$gradient-accent-color: linear-gradient(
  112.07deg,
  #ed2d2d 2.13%,
  #f6c566 99.26%
);

//Text Body

$text-title-color: #14142a;
$text-body-color: #4e4b66;
$text-label-color: #6e7191;
$text-placeholder-color: black;

// Background

$background: #d9dbe9;
$background-color: #f7f7fc;
$line-color: black;
$off-white-color: #fcfcfc;
$input-background-color: black;

@import "node_modules/bootstrap/scss/bootstrap";
