.header-routes-container{
    display: flex;
    align-items: center;
    
    .title-planos {
        font-weight: bold;
        font-size: 25px;
        padding-left: 10px;
    
        @include mobile {
          margin-left: 5px;
        }
    
      }
}