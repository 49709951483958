@import "styles/theme.scss";
@import "styles/responsive.scss";
@import "styles/tipography.scss";
@import "styles/shadow.scss";

//Components
@import "components/Input/input.scss";
@import "components/Button/button.scss";
@import "components/PieChart/pieChart.scss";
@import "components/Chart/chart.scss";
@import "components/StackedBarChart/stackedBarChart.scss";
@import "components/Card/card.scss";
@import "components/Modal/modal.scss";
@import "components/CheckBox/check-box.scss";
@import "components/Avatar/avatar.scss";
@import "components/Table/table.scss";
@import "components/Swal/swal.scss";
@import "components/CheckList/check-lists.scss";
@import "components/Select/select.scss";
@import "components/Progress/progress.scss";
@import "components/Dashboard/dashboard.scss";
@import "components/Menu/menu.scss";
@import "components/DropDown/dropDown.scss";
@import "components/Dropzone/dropzone.scss";
@import "components/Switch/switch.scss";
@import "components/Selector/selector.scss";
@import "components/TableCard/table-card.scss";
@import "components/DetailsRegister/details-register.scss";
@import "components/DetailsPlan/details-plan.scss";
@import "components/DocumentsDetails/details-documents.scss";
@import "components/HeaderRoutes/header-routes.scss";
@import "components/LoadingComponent/loading.scss";
@import "components/NotResultComponent/styles.scss";
@import "components/columnMobile/collumn.scss";
@import "components/ConsumptionExtractTable/consumptionExtraTable.scss";

//Screens
@import "screens/HeaderScreen/header-screen.scss";
@import "screens/Login/login-screen.scss";
@import "screens/Sidebar/sidebar.scss";
@import "screens/RegisterScreen/register-screen.scss";
@import "screens/UserScreen/User.scss";
@import "screens/CalendarScreen/calendar-screen.scss";
@import "screens/HomeTicketScreen/home-ticker-screen.scss";
@import "screens/HomeTicketScreen/home-ticker-screen.scss";
@import "screens/PaynamentDetailsScreen/paynament-details.scss";
@import "screens/TicketDetailsScreen/ticket-details.scss";
// @import "screens/HomeScreen/Home.scss";
@import "screens/DocumentsScreen/Documents-screen.scss";
@import "screens/HomeScreenWithData/home-screen.scss";
@import "screens/HomeScreenNotData/home-screen.scss";
@import "screens/HomeDetailsScreen/home-details-screen.scss";
@import "screens/ConsumptionDetailsScreen/consumption-details-screen.scss";
@import "screens/ConsumptionExtractScreen/Consumption-extracte-screen.scss";
@import "screens/RepurchaseScreen/repurchase-screen.scss";
@import "screens/PackagesScreen/Packages-screen.scss";

//iframe abrindo no meio da tela
body > iframe {
  display: none;
}

::-webkit-scrollbar-track {
  background: #ccc;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #5e5e66;
}

::-webkit-scrollbar-thumb {
  background: #5e5e66;
  border-radius: 5px;
}

.container-app {
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-direction: row;

  @include mobile {
    padding-top: 91px;
  }

  .body-app {
    flex: 1;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: #f7f7f7;

    @include mobile {
    }
  }

  .admin-screen-content {
    overflow: hidden;
    display: flex;
    height: calc(100vh - 71px);
    background-color: #f7f7f7;

    @include mobile {
      height: calc(100vh - 129px);
    }
  }

  .admin-screen-main {
    flex: 1;
    overflow-y: hidden;
  }

  @include mobile {
    &.collapsed {
      flex-direction: column;
      display: block;
    }

    &.open {
      .body-app {
        display: none;
        overflow: hidden;
      }

      .admin-nav-avatar {
        display: none;
      }
    }
  }
}
