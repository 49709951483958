
.line-extrato { 
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap:2rem;
    height: 100%;

    .main-info{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap:2rem;
        height: 100%;
        padding-right: 10px;
    }

    .info-left {
        display: flex;
        gap:2rem 
    }

    .info-dentro{
        display: flex;
        flex-direction: column;
        justify-content: start;
        
        gap: 0.5rem;
    }
    .name {
        font-size: 1.1rem;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }
    .color{
        height:60px ;
    }
}


