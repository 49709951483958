.custom-button {
    width: 150px;
    padding: 10px 20px;
    margin: 25px 0px 10px 30px;
    background-color: #4B4B4D;
    color: #fff;
    border: solid;
    border-color: white;
    border-radius: 15px;
    cursor: pointer;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;

    @include mobile {
        width: 135px;
    }

    &.highlighted {
        background-color: #4B4B4D;
        border-color: $primary-color;
        color: $primary-color;
        
    }
}