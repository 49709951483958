.consumption-container {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  overflow: hidden;
  padding: 25px;

  @include mobile {
    margin-bottom: 50px !important;
    padding-top: 10px;
  }

  .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
    align-items: center;
    .select-size {
      margin-bottom: 0px !important;

      .css-19mg7m3-option {
        color: #000 !important;
        font-family: Poppins, sans-serif;
        font-size: 14px;
      }
      .css-1jqq78o-placeholder {
        // background-color: #eff0f6 !important;
        color: #a5a8c1 !important;
        font-weight: 600;
        font-family: Poppins, sans-serif;
        font-size: 14px;
      }

      .css-13cymwt-control {
        height: 45px !important;
        border: 0px !important;
        background-color: #eff0f6 !important;
        min-width: 200px;
      }

      .css-sk63cg-control {
        height: 45px !important;
        border: 0px !important;
        background-color: #eff0f6 !important;
        min-width: 200px;
        outline: none !important;
      }
      .css-1nmdiq5-menu {
        background-color: #eff0f6 !important;
        z-index: 999999999 !important;
      }

      .css-qbdosj-Input {
        color: #a5a8c1 !important;
        font-weight: 600;
        font-family: Poppins, sans-serif;
        font-size: 14px;
      }

      .css-g4ngr7-option {
        color: #a5a8c1 !important;
        font-family: Poppins, sans-serif;
        font-size: 14px;
      }

      .css-1qmiu5g-option {
        color: #a5a8c1 !important;
        font-family: Poppins, sans-serif;
        font-size: 14px;
      }
    }
    .filter-button {
      padding: 10px;

      .icon-filter {
        margin-right: 10px;

        @include mobile {
          margin-right: 0px !important;
        }
      }
    }

    .new-button {
      padding: 10px 15px;
      margin-left: 15px;
      background-color: #ff004d;
      border: 0px !important;
      height: 45px;
      border-radius: 4px !important;

      .icon-filter {
        margin-right: 0px;

        @include mobile {
          margin-right: 0 !important;
        }
      }
    }

    .input-size {
      // color: red;
      background-color: #eff0f6;
      border: none !important;
      width: auto !important;
    }

    .input-group {
      height: 45px;

      input::placeholder {
        color: #a0a3bd !important;
        display: flex;
      }
    }

    .input-group-text {
      height: 45px !important;
      background-color: #eff0f6;
    }

    .search-Icon {
      color: #a0a3bd !important;
    }
  }

  .tabs {
    background-color: #d9dbe9;
    color: black !important;
    height: 50px;
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: 8px 8px 0px 0px !important;
    cursor: pointer;
    // margin-right: 10px;
  }

  .react-tabs__tab--selected {
    background-color: white !important;
    border-radius: 8px 8px 0px 0px !important;
    padding: 15px;
    display: flex;
    align-items: center;
  }

  .tab-panel {
    background-color: white;
    //height: 100px;
    .div-table {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column !important;
      padding: 20px;
      overflow: hidden;
      height: calc(100vh - 280px);

      @include mobile {
        padding: 20px 15px;
        height: calc(100vh - 308px);
      }

      .div-titles {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        background-color: #f7f7fc;
        width: 100% !important;
        height: 35px !important;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 8px;

        .title {
          color: black;
          font-size: medium;
          font-weight: 500;
          align-items: center;
          display: flex;
        }
      }
    }

    .sub-title {
      display: flex;
      width: 100%;
      justify-content: flex-start !important;
      margin-bottom: 10px;

      @include mobile {
      }
    }

    .rgt-wrapper {
      border: none !important;
      min-height: 50px !important;
    }

    .rgt-cell {
      justify-content: center;
    }

    .rgt-text-truncate {
      font-weight: 600;
      font-size: 16px;
    }

    .rgt-cell-header-inner-not-pinned-right {
      border: none !important;
      background-color: #f7f7fc;
    }

    .rgt-cell-header-inner {
      border: none !important;
      background-color: #f7f7fc;
      justify-content: space-around;
      padding: 0px;
    }

    .rgt-footer {
      border: none !important;
    }

    .rgt-header-container {
      border: none !important;
    }

    .rgt-cell-header-pinned-right {
      border: none;
    }

    .rgt-cell-header-sticky {
      border: none;
    }

    .rgt-container {
      .rgt-cell-header {
        &:nth-child(1) {
          .rgt-cell-header-inner {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
          }
        }

        &:nth-child(6) {
          .rgt-cell-header-inner {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
          }
        }
        &:nth-child(7) {
          .rgt-cell-header-inner {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
          }
        }
      }
    }
  }

  .documents-input {
    width: 100% !important;
    color: black !important;

    .form-control {
      background-color: #eff0f6 !important;
      border: none !important;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }

  .div-documents {
    width: 100%;

    @include mobile {
      // max-height: 240px;
      height: calc(100vh - 500px);
      overflow: auto;
      padding: 15px;
    }

    .input-container {
      width: 100%;
      flex-direction: row;
      display: flex;
      align-items: center;

      .input {
        background-color: #eff0f6;
        width: 100% !important;
        // height: 80px;
        border-radius: 8px;
        margin-bottom: 15px;
        margin-top: 5px;
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        padding: 15px;
      }

      .title-input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .input-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include mobile {
          flex-direction: column;
        }

        .process {
          display: flex;
          flex-direction: row;
          align-items: center;

          @include mobile {
          }

          .subtitle-input {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            margin-right: 10px;
          }
        }

        .button-documents {
          border: none;

          @include mobile {
            height: 60px;
            margin-top: 5px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .div-button {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: flex-end;

    @include mobile {
      margin-top: 10px;
    }

    .button-cancel {
      background-color: transparent;
      color: black;
      margin-right: 5px;
    }

    .button-send {
      background-color: #27bc67;
      border: none;
      margin-left: 5px;
    }
  }
}

.div-icon {
  display: flex;
  flex-direction: row;
  align-items: center;

  // .field-actions {
  //   background-color: #f7f7f7;
  //   height: auto;
  //   width: 30px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 8px;
  //   margin-right: 15px;
  //   cursor: pointer;
  // }

  .icons-action {
    font-size: 16px;
    cursor: pointer;

    &.delete {
      color: red;
    }
  }
}

.button span {
  font-weight: 600;
  font-size: 12px;
  line-height: none !important;
  margin: 0px 0px;
}

.status {
  background-color: #008a00;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  padding: 2px;
  font-size: 14px;

  @include mobile {
    justify-content: flex-start;
    align-items: start;
    height: 100%;
    width: 10px;
    border-radius: 0;
  }

  &.reprovado {
    background-color: #ff004d;
  }

  &.processamento {
    background-color: #a0a3bd;
  }

  &.fechado {
    background-color: #ff9100;
  }

  &.analise {
    background-color: #ffd809;
  }

  &.liberado {
    background-color: blue;
  }
}

.rescue-button {
  display: flex;
  border: 2px solid #008a00;
  padding: 6px 10px;

  &.aproved {
    background-color: #008a00 !important;
    cursor: no-drop;
  }

  &.requested {
    border: 0px;
    cursor: no-drop;
  }
}
