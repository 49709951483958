.custom-tooltip {
    background-color: white;
    padding: 0.2em 0.5em;
    border-radius: 0.5em;
    flex-direction: row;
    display: flex;

    .label {
        margin-bottom: 0px;
        color: $text-label-color;

        &.text {
            color: $text-body-color;
            font-weight: bold;
            margin-left: 5px;
        }
    }
}