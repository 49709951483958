.div-input {
    align-items: center;
    display: flex;
 margin-bottom: 15px;
    margin-right: 20px;

    &.full {
        width: 100%;
    }
}

.check-box-input {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    cursor: pointer;
    accent-color: $primary-color;
}

.check-text {
    font-weight: 400;
    font-size: 14px;
    color: #434449;

    &.parent {
        font-weight: bold;
        margin-right: 2px;
    }
}

.input-control-check {
    &.mb-3 {
        width: 100% !important;
    }
    div[class*="input-group"] {
        .form-control {

           
            display: flex;
            align-items: center;
            border: 0px;
            border-radius:0;
        
            
        }
    }
}