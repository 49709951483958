.modal-home-container {
  background-color: #ffffff;
  border: 0 !important;
  flex: 1;
  display: flex;
  padding: 25px;
  padding-top: 5px !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start; //space-between
  overflow-y: scroll;

  @include mobile {
    padding: 10px;
  }

  .modal-header {
    display: flex;
    flex-direction: row;
    // height: 60px !important;
    border-bottom: 1px solid #d9dbe9;
    padding: 0px;
    margin-bottom: 20px;

    .header-title {
      display: flex;
      flex-direction: row;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .modal-home-body {
    display: flex;
    align-items: end;
    flex-direction: column;
    height: 100%;

    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      width: 100%;

      .file {
        padding: 15px;
        background-color: #cbffae;
        border-radius: 6px;
      }

      .title {
        font-weight: bold;
        font-size: 16px;
        color: #000000;
        margin-top: 10px;
        margin-bottom: 8px;
      }

      .text {
        font-weight: 500;
        font-size: 14px;
        color: #000000;
      }
    }

    .close-btn {
    }
  }
}
