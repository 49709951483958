.modal-ticket-container {
    background-color: #ffffff;
    border: 0 !important;
    flex: 1;
    display: flex;
    padding: 25px;
    padding-top: 5px !important;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: flex-start; //space-between
    overflow-y: auto;
  
    @include mobile {
      padding: 10px;
    }
  
    .modal-header {
      display: flex;
      flex-direction: row;
      // height: 60px !important;
      border-bottom: 1px solid #d9dbe9;
      padding: 0px;
      margin-bottom: 20px;
  
      .header-title {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        font-size: 20px;
      }
    }
  
    .modal-consumption-body {
      display: flex;
      flex-direction: column;
      height: 100%;
  
  
      .text-contract{
        font-size: 14px;
        font-weight: 400;
        color: rgba(110, 113, 145, 1);
  
        &.or{
          margin-bottom: 10px;
        }
      }
  
      .button-contract{
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        flex-direction: column;
      }
  
      .documents-input{
  
        >div{
          >input{
            height: 40px;
          }
        }
       
      }
  
     .documents-select {
      width: 100% !important;
      color: black !important;
      >div{
        .css-13cymwt-control{
          height: 40px !important;
  
        }
        .css-sk63cg-control{
          height: 40px !important;
          border: 0px !important;
          outline: none !important;
        }
        >div{
          background-color:#eff0f6 !important ;
        }
      }
  
      div[class*="-Input"] {
        font-weight: normal;
        font-family: Poppins, sans-serif;
      }
  
      div[class*="-ValueContainer"] {
        padding: 6px 12px;
      }
  
      .css-13cymwt-control {
        color: black;
        background-color: #eff0f6;
        border: 0px !important;
      }
  
      .css-1jqq78o-placeholder {
        color: $text-label-color;
        font-size: 15px;
        font-weight: normal;
        font-family: Poppins, sans-serif;
      }
  
      .css-sk63cg-control {
        background-color: #eff0f6;
        border: 0px !important;
        outline: none !important;
      }
  
      .css-19mg7m3-option {
        background-color: #1967d2;
      }
      .css-16xfy0z-control{
  
        flex-wrap: nowrap;
      }
    }
  
      .documents-input {
        width: 100% !important;
        color: black !important;
  
        .generic-input-group{
          >textarea{
            height: 400px !important;
            resize: none;
          }  
  
        }
  
        >input{
          height: 40px !important;
        }
        >div{
          >div{
            >input{
  
              height: 40px !important;
            }
  
          }
        }
     
        .form-control {
          background-color: #eff0f6 !important;
          border: none !important;
  
        }
        &:focus{
          outline: 0;
          box-shadow: none;
        }
      }
  
      .row-dates {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
  
      .div-table {
        margin-top: 10px;
        .title-extract {
          font-size: 16px;
          color: black;
          font-weight: bold;
          height: calc(100vh - 250px);
          margin-bottom: 10px;
        }

        .list-transactions-div{
            display: flex;
            justify-content: space-between;
        }
        .table {
          tr {
            td {
              font-size: 14px;
              padding: 5px 0px 0px 0px;
            }
          }
        }
      }
    }
  }
  