.div-container {
    display: flex;
    background-color: $off-white-color;
    border-radius: 4px;
    border-left: 15px solid $success-color;
    margin: 10px;
    flex: 1;

    &.success {
        border-color: #ED2D2D;

    }

    &.warning {
        border-color: $warning-color;
    }

    &.no-border {
        border: 0px;
    }
}

.div-margin {
    margin: 15px;
    width: 100%;
}

.div-circle-icon {
    background-color: #FDD5D5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 7px;
    margin-right: 15px;

    &.success {
        background-color: $primary-color
    }

    &.warning {
        background-color: $warning-color
    }
}

.icon-alert {
    color: $error-color;

    &.success {
        color: $success-color;
    }

    &.warning {
        color: #ffc453
            /* $warning-color */
    }
}

.div-primary-flex {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.title-text {
    font-weight: bold;
}

.div-justify {
    display: flex;
    justify-content: space-between;

}

.icon-eye {
    color: #6D6E74;
    cursor: pointer;
}

.sub-text {
    font-weight: 400;
    font-size: 14px;
    color: #434449;
}

.list-checklist {
    height: auto !important;
 
    
    >div {
        height: auto !important;
    }
}
