@keyframes open_in {
  from {
    width: 0px;
  }

  to {
    width: 360px;
  }
}

@keyframes closeIn {
  from {
    width: 360px;
  }

  to {
    width: 0px;
  }
}

.lev-menu-container {
  position: fixed;
  height: 100%;
  background-color: white;
  z-index: 999;

  @include mobile {
    position: absolute;
    right: 0;
  }

  &:not(.left) {
    right: 0;
  }

  &.left {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
      0px 4px 20px -2px rgba(50, 50, 71, 0.08);
    transition: width, left, right, 0.3s;

    .media-credit-menu-button {
      display: none;
    }

    left: 80px;

    @include mobile {
      z-index: 9999999;
      width: 100%;
      left: 0px;
    }

    .media-credit-menu {
      overflow-x: hidden;
    }

    &.isOpen {
      left: 270px;
    }
  }

  .media-credit-menu {
    opacity: 1;
    width: 360px;

    @include mobile {
      width: 100vw;
    }

    &.closed {
    }

    &.open {
    }
  }

  .media-credit-menu-button {
    position: absolute;
    left: -40px;
    top: 135px;
    height: 36px;
    width: 36px;
    background-color: white;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
      0px 4px 20px -2px rgba(50, 50, 71, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      top: 17px;
      left: calc(100vw - 52px);
    }

    &.closed {
      left: -58px;
    }

    img {
      padding: 6px;
      height: 32px;
      width: 32px;
    }

    &:hover {
      background-color: $secondary-color;

      @include mobile {
        background-color: white;
      }
    }

    .icon {
      color: $primary-color;
      font-size: 18px;
    }

    &.open {
      background-color: $primary-color;

      .icon {
        color: white;
      }
    }

    .badge {
      background-color: $secondary-color;
      position: absolute;
      top: -5px;
      right: -5px;
      display: block;
      padding: 0.5em 0.5em;
      border-radius: 70px;
    }
  }
}

.side-filter {
  background-color: white;
  width: auto;
  padding: 15px;
  overflow: auto;
  height: calc(100vh - 65px);
  z-index: 9999999;

  h6 {
    padding-top: 25px;
  }

  .filter-style {
    width: 73px;
    height: 73px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.management-filter {
  display: flex;
  flex-direction: column;
  height: 100%;

  .date-range-calendar {
    max-width: inherit;
    width: 100%;

    .rdrMonth {
      width: 100%;
    }
  }

  .select-input-media-credit {
    margin-right: 0px;
  }

  .filters {
    flex: 1;

    .icons {
      display: flex;
      border-bottom: 1px solid #333;
      margin-bottom: 15px;
      justify-content: space-between;
      padding-inline: 10px;

      .icon {
        color: #333;
        padding-bottom: 10px;
        font-size: 32px;
        cursor: pointer;
        border-bottom: 2px solid transparent;

        &.active {
          color: $primary-color;
          border-bottom: 2px solid $primary-color;
        }
      }
    }

    .filter-title {
      &.title {
        padding-top: 0px;

        &.bottom {
          padding-bottom: 20px;
        }
      }
    }

    .select-input-media-credit {
      margin-bottom: 0px;
      margin-top: 15px;
    }
  }

  .clear-filter {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
