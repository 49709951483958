$tablet-width: 768px;
$desktop-width: 1024px;
@mixin mobile {
  @media (max-width: #{768px - 1px}) {
    @content;
  }
}
@mixin mini-mobile {
  @media (max-width: #{321px - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}