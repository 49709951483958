$valueCss: false;

.dropzone-container {
  background-color: #eff0f6;
  border-radius: 8px;
  padding: 12px;
  max-height: 250px;
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  // min-width: 145px;
  // min-height: 117px;
  //border: #d9dbe9 dashed 2px;
  min-height: 100px;

  .row-btns-documents{
  display: flex;
  align-items: center;

  .icon-files{
    position: relative;
    height: 30px;
    width: 50px;
    .icon-multiple-delete{
      font-size: 8px;
      color:red;
      cursor: pointer;
      position: absolute;
    }

    .icon-multiple-files{
      font-size: 25px;
      cursor: pointer;
    }
  }
  >svg{
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
  }
  }

  @include mobile {
    max-width: 100%;
  }
.div-custom-drop{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .span-palceholder{
    font-size: 1rem;
    font-weight: 400;
    background-clip: padding-box;
    color: #6c757d;
  }
}

  .dropzone {
    display: flex;
    //flex: 1;
    align-items: center;
   
    justify-content: space-between;
  }

  p {
    margin-bottom: 0px;
    font-weight: 500;
    letter-spacing: 0.25px;
    color: #6e7191;
  }
  
  .valueTrue{
    display: flex;
    flex-direction: column;
    text-align: start;

    p#info{
      color: #008A00;
    }
  }

  .div-buttonZone{
    flex-direction: row;
    display: flex;
    background-color: transparent;
    justify-content: flex-end;
  }
  .button {
    background-color: #d9dbe9;
    &.buttonYelow {
      background-color: #FFD809 !important;
      border: none;
    }
    &.buttonRed {
      background-color: rgb(255, 0, 77) !important;
      border: none;
    }
    &.buttonDef{
      background-color: black !important;
      color: white;
      border: none;
    }
  }
  &.multiple {
    max-width: inherit;
    max-height: inherit;
    padding: 8px;

    .div-multiples {
      display: flex;
      flex-wrap: wrap;

      .div-multiple {
        position: relative;
        display: flex;
        margin: 2px;
        background-color: #606062;

        .multiple-image {
          max-height: 250px;
          object-fit: contain;
        }

        .div-icons-multiple {
          position: absolute;
          top: 7px;
          right: 10px;

          .icon-multiple {
            color: $primary-color;
            margin: 2px;
            width: 16px;
            height: 16px;

            @include mobile {
              width: 20px;
              height: 20px;
            }

            &.delete {
              color: $error-color;
            }
          }
        }
      }
    }
  }
}
