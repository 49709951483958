.media-credit-modal {
  max-width: 50vw;
  background-color: #ffffff;
  display: flex;
  flex: 1;
  outline: none !important;

  @include mobile {
    max-width: 100%;
    margin-top: 91px;
  }
}

.ReactModal__Overlay {
  z-index: 9999;
  display: flex;
  margin-left: 0 !important;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.modal-body {
  display: contents !important;

  > div {
    width: 100%;
  }
}

.swal-close {
  background-color: #f16063;

  &:hover {
    background-color: #cf4655 !important;
  }
}

.swal-save {
  background-color: #66cb9f;

  &:hover {
    background-color: #4aae8c !important;
  }
}

.header-buttons {
  display: flex;
}

.modal-service-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    display: flex;
    flex-direction: row;
    margin-bottom: 120px;

    .form-switch {
      margin-bottom: 0px !important;
    }

    > div {
      flex: 1;
      align-self: center;
    }
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  height: 60px !important;
  border-bottom: 1px solid #7a7a7e;
  padding: 0px;

  .header-title {
    display: flex;
    flex-direction: row;
    &.step {
      align-items: center;
    }
  }
}

.header-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.input-select {
  margin-inline: 5px;
  width: 25% !important;
  font-size: 14px;
  background-color: #f7f7fc;

  div[class*="css-b62m3t-container"] {
    .css-1s2u09g-control {
      background-color: #f7f7fc !important;
      height: 40px;
    }

    .css-jzvvb1-control:active {
      background-color: #f7f7fc !important;
    }
  }

  &.dashboard {
    background-color: transparent;

    div[class*="css-b62m3t-container"] {
      .css-1s2u09g-control {
        height: 100%;
      }
    }
  }
}

.input {
  margin-inline: 5px;
  width: 25% !important;
  font-size: 14px;
  background-color: #f7f7fc;

  div[class*="input-group"] {
    .form-control {
      height: 40px !important;
      color: white;
      background-color: #f7f7fc;
    }
  }
}

.button-icon {
  font-size: 20px;
}

.form-control:focus {
  border-color: black !important;
}

.modal-footer-button {
  cursor: pointer !important;
  padding: 9px 15px !important;
  margin-left: 20px;
  border-radius: 4px;
  height: 35px;
  display: flex;
  align-items: center;

  &.cancel {
    background-color: transparent;
  }

  @include mobile {
    span {
      display: none;
    }
  }
}

.close-icon {
  cursor: pointer !important;
}

.text {
  &.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: #25262a;
    margin-right: 0px !important;
  }

  &.subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #434449;
  }
}

.sub-title-check {
  font-weight: 400;
  font-size: 14px;
  color: #6d6e74;
  flex: 1;
}

.input-control {
  flex: 1;

  div[class*="input-group"] {
    .form-control {
      background-color: transparent !important;
      font-weight: 700;
      font-size: 20px;
      display: flex;
      align-items: center;
      border: 0px;
      color: #f7f7fc !important;
      opacity: 1 !important;
      padding: 0px;
    }
  }
}

.div-bottom {
  margin-bottom: 40px;
}

.div-justify {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.btn-icon {
  border: 1px solid $primary-color !important;
  border-radius: 4px;
}

.dropzone {
  display: grid;
}

.square-value {
  background-color: #eff6ef;
  border-radius: 4px;
  min-width: 30px;
  display: flex;
  justify-content: center;
}

.text-value {
  font-weight: 700;
  font-size: 14px;
  color: #6d6e74;
}

.icon-calendar {
  font-size: 25px;
  color: $primary-color;
  cursor: pointer;
  margin-right: 15px;
}

.icon-more {
  font-size: 20px;
  margin: 5px 15px 5px 15px;

  &.list {
    margin: 5px 10px 5px 10px;
  }
}

.div-row {
  display: flex;
  align-items: center;

  &.header {
    flex: 1;

    &.select {
      margin-bottom: 10px;
    }
  }

  &.select {
    justify-content: space-between;
  }
}

.div-btns {
  display: flex;
  width: 100%;
  bottom: 10px;
  position: absolute;
  flex: 1;
  justify-content: space-between;
  padding: 15px;

  button {
    height: 40px;
    padding: 0.375rem 0.75rem;
  }

  > div {
    flex: 1;
    text-align: right;
  }
}

.save-btn {
  border-radius: 4px;
  background: $success-color;

  &.finaly {
    border-radius: 4px;
    background: transparent;
    border: 1px solid $success-color;
    color: $success-color;
  }
}

.cancel-btn {
  border-radius: 4px;
  background: transparent;
  border: 1px solid #97989e;
  color: #97989e;
  margin-right: 15px;

  &:hover {
    color: #97989e;
    background-color: transparent;

    &.dark {
      background-color: $primary-color;
    }
  }
}

.modal-footer-space {
  padding: 15px;
}

.btn-icon-list {
  background-color: $off-white-color !important;
  border-radius: 0;
  margin-left: 15px;
}

.generic-date-input {
  position: absolute !important;
  top: 190px;
  z-index: 9999;
}

.div-row-date {
  display: flex;
  cursor: pointer;
  align-items: center;

  &.finished {
    cursor: default;

    .icon-calendar {
      cursor: default;
    }
  }
}

.text-date {
  font-weight: 700;
  font-size: 14px;
  color: #6d6e74;
  margin-right: 15px;
}

.modal-container {
  flex: 1;
  display: flex;
  padding: 25px;
  padding-top: 5px !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  overflow-y: scroll;
  background-color: #ffffff;

  @include mobile {
    padding: 10px;
  }
}

.modal-service-container {
  flex: 1;
  display: flex;
  padding: 25px;
  padding-top: 5px !important;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: flex-start;
  overflow-y: hidden;

  @include mobile {
    padding: 7px !important;
  }
}

.modal-title {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.desc-input {
  flex: 1;

  .default-input {
    color: white;
  }

  textarea {
    height: auto;

    &:focus {
      box-shadow: none;
    }
  }

  &.company {
    .default-table {
      margin-bottom: 10px !important;
    }

    .rgt-wrapper {
      min-height: auto;
      height: 200px;

      @include mobile {
        height: 175px;
      }
    }

    .rgt-cell-header:nth-child(2) {
      .rgt-cell-header-inner {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        padding: 0px;
      }
    }

    .rgt-cell-header:nth-child(3) {
      .rgt-cell-header-inner {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 0px 9px;
      }
    }
  }

  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    background-color: #5e5e66;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
  }

  // &::-webkit-scrollbar-track {
  //   background-color: #5e5e66;
  //   border-radius: 15px;
  // }
}

.default-table
  .rgt-wrapper
  .rgt-container
  .rgt-cell-header:first-child
  .rgt-cell-header-inner {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding-left: 10px !important;
}

.default-table
  .rgt-wrapper
  .rgt-container
  .rgt-cell-header:nth-child(2)
  .rgt-cell-header-inner {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding: 20px !important;
}

.default-table
  .rgt-wrapper
  .rgt-container
  .rgt-cell-header
  .rgt-cell-header-inner {
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  padding: 10px !important;
}

.desc-plate {
  display: flex;
  color: white !important;

  .form-label {
    color: white;
  }
}

.client-vehicles-table {
  padding: 0px;

  > div {
    > div {
      padding-top: 0.9vh;
      padding-bottom: 0.1vh;
      display: flex;

      > label {
        display: flex;
        flex: 1;
        margin-bottom: 0px;
        align-items: center;
      }
    }
  }
}

.entrada-plate {
  border-radius: 0px !important;
}

.text {
  color: white;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
}

.add-button {
  display: inline-flex;
  height: 35px;
  align-items: center;
}

.add-button-position {
  text-align: end;
  margin-left: 125.5vh;
  margin-top: 0.5vh;
}

.show-data {
  color: white;
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

.custom-radio {
  display: -webkit-box;
  color: white;
  margin-top: 20px !important;
}

.showFieldsText {
  color: white;
  margin-right: 12px;
  cursor: unset;
}

.icon-arrow {
  font-size: 170%;

  color: rgb(243, 238, 238);

  &.color {
    color: rgb(145, 145, 145);
  }
}

.close-Icon {
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.generic-input {
  .label {
    color: white !important;
  }
}

.checklist-obs {
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
}

.check-box-input {
  color: #d9dbe9 !important;
  border-radius: 8px !important;
}

.input-group {
  border-radius: 8px !important;
}

.input-group-text {
  background: #67676a;
  border: 0;
  border-radius: 8px;
  padding: 0;
  padding-inline: 10px !important;
  height: 40px !important;
}

.default-input {
  color: black !important;
  border: 0 !important;
  border-radius: 8px;
}

.desc-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @include mobile {
    margin-bottom: 15px;
    flex-direction: column;
    align-items: stretch;
    margin-inline: 10px;
  }

  .generic-input {
    flex: 1;

    @include mobile {
      margin: 0px !important;
      margin-bottom: 10px !important;
    }

    &:first-child {
      margin-right: 10px;

      @include mobile {
        margin-right: 5px;
      }
    }

    &:last-child {
      margin-left: 10px;

      @include mobile {
        margin-left: 5px;
      }
    }
  }

  &.last {
    @include mobile {
      .generic-input {
        &:last-child {
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

.div-name-cnpj {
  margin-left: 20px;
  flex: 2;

  @include mobile {
    margin-left: 0px;
  }
}

.address-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  .generic-input {
    flex: 1;

    &:first-child {
      margin-right: 10px;

      @include mobile {
        margin-right: 0px;
      }
    }

    &:last-child {
      margin-left: 10px;

      @include mobile {
        margin-left: 0px;
      }
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: inherit;
  }
}

.search-Icon {
  color: white;
}

.percent-label {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.row {
  flex-direction: row;
  display: flex;
}

.col {
  flex-direction: column;
  display: flex;
}

.add-btn {
  background-color: #d9dbe9 !important;
  color: white;
  height: 40px;
  width: 45px;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  &:hover {
    opacity: 0.6;
  }
}

.modal-button-add {
  background-color: #d9dbe9 !important;
  color: white;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  &:hover {
    opacity: 0.6;
  }
}

.input-size {
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  align-items: flex-end;
}

::placeholder {
}

.sub-services {
  margin-top: 45px;
  overflow: hidden;
}

.sub-services-render {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px;
}

.sub-services-info {
  color: #f7f7fc;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 40px;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  border-radius: 8px;
  width: 70%;
  padding: 15px;
  background-color: #67676a;
}

.delete-btn-modal {
  background-color: #ffaaa4 !important;
  color: #e82314;
  height: 40px;
  width: 45px;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  &:hover {
    opacity: 0.6;
  }
}

.button-icon-cancel {
  color: #ffffff !important;
  font-size: 20px;
}

.cancel {
  color: white !important;
  border: 1px white solid !important;

  &:hover {
    background-color: #67676a !important;
  }
}

.main-upper-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 2;

  @include mobile {
    display: flex;
  }
}

.main-upper-div-for-checklist {
  align-items: center;
  flex-direction: row;
  flex: 2;
}

.main-div-inputs {
  align-items: center;
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
}

.lower-div-for-value-hour-chart {
  padding-top: 25px;
  width: 100%;

  > div {
    width: 100%;
  }

  > div > div {
    width: 100%;
  }
}

.inside-lower-div-value-hour-chart {
  display: flex;
}

.div-name-vehicle-infos {
  margin-left: 0px;
  margin-right: 10px;
  flex: 0.8;

  @include mobile {
    margin-left: 0px;
  }
}

.div-dropzone {
  flex: 0.4;
}

.react-tabs {
  display: flex;
  flex-direction: column;
}

.react-tabs__tab-list {
  margin-top: 15px !important;
  border: 0 !important;
  display: flex;
  margin-bottom: 0px !important;
  padding: 0;
  color: white;
  align-content: center;
  flex-wrap: wrap;
}

.react-tabs__tab {
  display: inline-block;
  background-color: #67676a;
  border-bottom: none;
  bottom: -5px;
  left: -0.5px;
  position: relative;
  text-align: center;
  margin: 0.5px;
  padding: 6px 12px;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  svg {
    margin-bottom: 2px;
  }

  @include mobile {
    text {
      display: none;
    }
  }
}

.react-tabs__tab--selected {
  border-color: #33343570 !important;
  text-align: center;
  color: white !important;
  background-color: #33343570 !important;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab:focus:after {
  position: absolute;
  height: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
}

.react-tabs__tab-panel {
  padding: 10px;
  margin-top: 4px;

  @include mobile {
    padding: 0px;
  }
}

.react-tabs__tab-panel--selected {
  display: block;
  background-color: #33343570;
  border-radius: 0px 8px 8px 8px;
}

.div-dropzone-vehicles {
}

.void-class {
  div[class*="-option"] {
    background-color: #67676a !important;
    color: white !important;
  }

  div[class*="-control"] {
    box-shadow: 0 0 0 0px #67676a !important;
    border-color: #67676a !important;
    color: white !important;
    background-color: #67676a;
    font-size: 13px;
  }

  div[class*="-singleValue"] {
    color: white !important;
    background-color: #67676a;
  }

  div[class*="-menu"] {
    color: white !important;
    background-color: #67676a;
  }
}

.checklist-items {
  display: flex;
  width: 100%;
}

.production-phase-main-upper-div {
  display: flex;
  flex: 2;
  flex-direction: row;
}

.new-checklist-description {
  margin: 5px 0px 0px 0px !important;

  @include mobile {
    margin: 0px !important;
  }

  > div {
    @include mobile {
      width: auto;
    }
  }
}

.percentage-value {
  margin: 0px 0px 0px 5px;

  @include mobile {
    margin: 0px;
  }

  > div {
    width: 130px;

    @include mobile {
      width: auto;
    }
  }
}

.percentage-content {
  > div {
    @include mobile {
      width: 100% !important;
      padding-right: 10px !important;
    }
  }
}

.description-add-button-placement {
  padding-left: 20px;
  padding-top: 37px;
  padding-right: 5px;

  @include mobile {
    padding-left: 20px;
    padding-top: 31px;
    padding-right: 5px;
  }
}

.description-add-button {
  color: black !important;
  background-color: white !important;
  height: 40px !important;
  width: 50px !important;
  justify-content: center;
  align-items: center;
  display: flex;

  > span {
    margin: 0px !important;
    font-size: 20px !important;
  }
}

.new-checklist-item {
  width: 125%;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.checkbox-wrapper input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.checkbox-wrapper label {
  font-size: 18px;
}

.value-hour-input {
  background-color: #67676a !important;
  border: solid #67676a;
  border-radius: 10px;
  text-align: center;
  height: 40px;

  > div > input {
    background-color: #67676a;
    font-size: 15px;
  }
}

.price-placement {
  padding-top: 15px;
}

.div-production-description {
  width: 100%;
}

.div-hour-value-description {
  width: 100%;

  .generic-input,
  .mb-3 {
    margin: 0px !important;
  }
}

.value-hour-price-input {
  width: 140px;

  &.mb-3 {
    margin-left: 20px !important;
  }

  > div > input {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }

  @include mobile {
    width: 75px;
  }
}

.service-description {
  width: 100%;
  margin-bottom: 15px;

  > div > div {
    @include mobile {
      width: 100%;
    }
  }

  .generic-input {
    margin: 0px !important;
  }
}

.small-size-input-frequency {
  padding-bottom: 10px;
  padding-top: 10px;

  > div {
    width: 100%;
    display: flex;
    text-align-last: center;
  }

  > div > input {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: white;

    @include mobile {
      padding: 2px 0px 0px 0px !important;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.service-size-content {
  padding-top: 16px;

  @include mobile {
    padding-top: 15px !important;
  }

  > div {
    padding: 5px 0px 5px 0px !important;
  }
}

.first-service-size-content {
  padding-top: 20px;
  padding-right: 60px;

  @include mobile {
    padding-right: 10px;
  }

  > div {
    margin: 0px !important;
  }
}

.middle-service-size-content {
  padding-top: 20px;
  padding-right: 60px;

  @include mobile {
    padding-right: 10px;
  }

  > div {
    margin: 0px !important;
  }

  > div > div {
    @include mobile {
      width: 80px !important;
    }
  }
}

.last-service-size-content {
  padding-top: 20px;

  > div {
    margin: 0px !important;
  }

  > div > div {
    @include mobile {
      width: 80px !important;
    }
  }
}

.normal-size-input {
  padding-bottom: 10px;
  padding-top: 10px;

  > div {
    width: 150px;
    display: flex;
  }

  > div > input {
    height: 40px;
    display: flex;
    font-size: 16px;
    text-align: center;
  }
}

.normal-size-result {
  padding-bottom: 10px;
  padding-top: 10px;

  opacity: 0.5 !important;

  > div {
    width: 150px;
    display: flex;
  }

  > div > input {
    height: 40px;
    display: flex;
    font-size: 16px;
    text-align: center;
  }
}

.message-area-min {
  margin: 0px !important;

  @include mobile {
    > div {
      min-width: 81vw;
    }
  }

  > div > textarea {
    min-height: 200px !important;
    height: 200px;
  }
}

.lower-aesthetic-div {
}

.item-title {
  border-radius: 8px;
  border: 0px;
  outline: 0;
  width: 100%;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: white;
  background-color: #67676a;
  padding: 10px;

  &:focus {
    color: white;
    background-color: #67676a;
  }

  @include mobile {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

.service-img-placement {
  text-align: center;

  img {
    width: 85%;
  }

  > div {
    margin: 0px !important;
  }

  > div > section {
    min-width: 100% !important;
    min-height: 300px !important;

    @include mobile {
      min-width: 107% !important;
    }
  }

  > div > section > div {
    min-height: 270px !important;
  }

  > div > section > div > img {
    max-width: 100% !important;
    max-height: 260px !important;
  }
}

.btn {
  .danger {
    border-color: red;
    background-color: red;
    color: black;

    &:focus {
      border-color: red;
      background: red;
      color: black;
    }
  }

  .confirm {
    border-color: green;
    background-color: green;
    color: black;

    &:focus {
      border-color: green;
      background: green;
      color: black;
    }
  }
}

.hour-input {
  padding-bottom: 10px;
  padding-top: 10px;

  > div {
    width: 150px;
    display: flex;

    @include mobile {
      width: 80px;
    }
  }

  > div > div > input {
    padding-bottom: 10px;
    height: 40px;
    display: flex;
    font-size: 16px;
    text-align: center;
  }
}

.repeat-text {
  padding-left: 80px;

  @include mobile {
    padding-left: 10px;
  }
}

.subservice-item {
  margin: 5px 5px 0px 0px !important;
  padding-top: 5px;
  padding-right: 5px;

  @include mobile {
    padding-top: 0px;
    padding-right: 0px;
  }
}

.subservice-percent {
  margin: 10px 0px 0px 0px !important;

  @include mobile {
    margin: 5px 0px 0px 0px !important;
  }
}

.delete-service-placement {
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 10px;

  @include mobile {
    padding-top: 5px !important;
  }
}

.percentage-button-placement {
  margin-top: 30px;
  margin-left: 5px;
}

.service-type-description {
  margin-bottom: 0rem !important;
  @include mobile {
    margin: 0px !important;
  }
}

.service-type-description-placement {
  width: 80%;
}

.service-type-percentage-placement {
  width: 25%;
  margin-left: 5px;

  @include mobile {
    width: 23%;
  }
}

.small-size-input {
  &.mb-3 {
    padding-bottom: 7.5px !important;
    padding-top: 7.5px !important;
  }
  &.step {
    width: auto !important;
  }

  @include mobile {
    &.mb-3 {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }

  > div {
    width: 50px;
    display: flex;
  }

  > div > input {
    height: 40px;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}

.dropdown-item {
  text-align-last: center !important;

  &:active {
    color: black;
    text-decoration: none;
    background-color: $primary-color;
  }
}

.row-input-check {
  padding-right: 5px;
}
