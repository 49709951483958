.basic-table {
  border-collapse: collapse;
  width: 100%;

  .basic-table-thead {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    .basic-table-thead-tr {
      color: #4E4B66;

    }
  }

  tbody {
    tr {
      height: 45px;
      border-radius: 8px;
    }
  }
}

.action-sheet-react-data {
  &.with-data {
    .action-sheet {
      z-index: 99999999 !important;
    }
  }

  .bar {
    height: 8px;
    width: 50px;
    background-color: $primary-color;
    margin: 0 auto;
    border-radius: 10px;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: move;
  }



  .label {
    cursor: pointer;
    width: 100%;
    font-size: 18px;
    color: $text-body-color;
    padding: 15px;
    border-bottom: 0.5px solid rgba(240, 240, 240, 0.5);
    transition: all 0.3s;

    &:last-child {
      color: $error-color;
    }
  }

  .label:hover {
    background-color: white;
  }

  .label:last-child {
    border-bottom: none;
  }
}



.td-tbody {
}

.icon-actions {
  font-size: 20px;
  color: #97989E;
  cursor: pointer;

  &.trash {
    color: $error-color;
  }

}


.btn-actions {
  display: flex;
  justify-content: center;

  svg {
    margin-inline: 10px;
  }
}

.basic-table tbody tr {
  height: 64px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;

  &:hover {
    background: #EFF0F6;
}
}

.barCompleted {
  background-color: #EFF6EF;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;

  >span {
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.progress-label {
  justify-content: center;
  display: flex;
  position: absolute;
  right: 50%;
  left: 50%;
  font-weight: 700;
  font-size: 14px;
  color: #6D6E74;
  margin: 0px
}

.progress-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}