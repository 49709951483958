@mixin background($imgpath, $position: 50% 50%, $repeat: no-repeat) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
  }
}

.recovery-container {
  @include mobile {
    .form {
      top: 75px !important;
      width: 25%;
    }
  }
}

.image {
  position: absolute;
  .fundo {
    background-color: $primary-color;
    width: 100vw;
    height: 100vh;
  }
}

.backWorker {
  width: 100vw;
  height: 100vh;
  filter: brightness(50%);

  @include mobile {
  }
}

.login-container,
.newPassword-container,
.recovery-container {
  display: flex;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding-right: 45px;

  @media (max-width: 1024px) {
    overflow: hidden;
    padding-inline: 0px;
  }

  &.menu {
    height: 100% !important;
  }

  .div-background {
    @include background("/assets/images/background-coin.svg", 0% 100%);
    display: flex;
    background-position: 0% 100%;
    background-repeat: no-repeat;
    width: 100%;
    background-size: contain;
    justify-content: end;
    height: 80vh;
    align-items: center;

    .background-img {
      z-index: 1;
    }

    @media (max-width: 1024px) {
      @include background("/assets/images/background-coin.svg");
      width: 100%;
      justify-content: center !important;
      background-position: unset;
      align-items: center;
      height: 100vh;
    }
  }

  .left {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 100px;
    color: white;

    @include mobile {
      display: none;
    }

   

    .line {
      width: 25%;
      height: 5px;
      background-color: white;
      border-radius: 100px;
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .description {
      letter-spacing: 2px;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 34px;
    }

    .logo {
      width: 107px;
    }

    .backWorker {
      width: 100vw;
      height: 100vh;
      background-color: #27bc67;
    }
  }

  .loginInfo {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
  }

  .loginLabel {
    color: black !important;
    .placeholder {
      color: black !important;
    }
  }

  .forgotPassword {
    font-size: 15px;
    font-weight: 300;
    margin-top: 5px;
    margin-bottom: 2px;
    cursor: pointer;
    color: $primary-color;
    align-items: center;
    justify-content: center;
    display: flex;
    &:hover {
      opacity: 0.3;
    }
  }

  .form {
    background-color: white !important;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    overflow: auto;
    height: 500px;
    z-index: 10;
    width: 45%;
    // min-height: 578px;
    padding:20px ;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    height: 80vh;


    &.outside {
      height: auto;

      .inputs .generic-input {
        margin-top: 10px !important;
      }
    }

    @include mobile {
      position: fixed !important;
      border-radius: 30px;
      align-items: center;
      text-align: center;
      width: 85%;
    }

    @include mini-mobile {
      position: fixed !important;
      border-radius: 30px;
      align-items: center;
      text-align: center;
      width: 90%;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      //position: absolute;
      width: 85%;
    }

    .logo {
      width: 250px;
      margin-bottom: 35px;

      @include mobile {
        width: 250px;
        margin-bottom: 0px;
      }
    }

    .title {
      color: black;
      font-weight: 700;
      font-size: 30px;
      letter-spacing: 1px;
      margin-bottom: 20px;

      @include mobile {
        margin-top: 30px;
        font-weight: 700;
        font-size: 28px;
        letter-spacing: 1px;
      }

      @include mini-mobile {
        margin-top: 30px;
        font-weight: 700;
        font-size: 28px;
        letter-spacing: 1px;
      }
    }

    .line {
      width: 15%;
      height: 5px;
      background-color: $primary-color;
      border-radius: 100px;
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .description {
      background-color: transparent !important;
      font-weight: 400;
      font-size: 18px;
      margin-top: 10px;
      font-size: large;

      @include mobile {
        margin-top: 0px;
        font-size: 16px;
      }
    }

    .login-input {
      width: 100% !important;
      color: black !important;
    }

    .inputs {
      text-align: left;
      width: 100%;
      margin-top: 15px;

      > div > div > input {
        color: black !important;
        background-color: #eff0f6;
      }

      .div-icon-eye {
        background: white !important;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        display: flex;
        align-items: center;
        padding: 10px;
        color: black;
        cursor: pointer;
      }

      .generic-input {
        margin-top: 30px !important;
        width: 100% !important;

        &.error {
          border-color: "red";
          background-color: #27bc67;
        }

        .label {
          margin-bottom: 10px;
        }
      }
    }

    .navButonLog {
      display: flex;
      flex-direction: column;

      .loginBtn {
        justify-content: center;
        display: flex;
        align-items: center;
        margin-top: 20px;
      }
    }

    .button {
      padding-inline: 40px;
      width: 100% !important;
    }

    .link-button {
      text-decoration: none;
    }
  }

  .backWorker {
    width: 100vw;
    height: 100vh;
  }

  .backBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .backIcon {
    color: $primary-color;
    margin-right: 10px;
    font-size: 26px;
  }

  .form-control {
    color: black !important;
    border: none;
  }
}

.login-container{
  .title-login {
    color: #000;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
    // letter-spacing: 1px;
  }
}


.info-h6 { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}