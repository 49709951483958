@mixin background($imgpath, $position: 50% 50%, $repeat: no-repeat) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
  }
}

.recovery-container {
  @include mobile {
    .form {
      top: 75px !important;
      width: 25%;
    }
  }
}

.image {
  position: absolute;
  .fundo {
    background-color: $primary-color;
    width: 100vw;
    height: 100vh;
  }
}

.backWorker {
  width: 100vw;
  height: 100vh;
  filter: brightness(50%);
}

.register-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  padding-right: 45px;
  @include mobile {
    padding: 0px;
  }

  @media (max-width: 1024px) {
    overflow: hidden;
    // padding-inline: 0px;
    justify-content: center;
  }

  &.menu {
    height: 100% !important;
  }

  .div-background {
    // height: 100%;
    display: flex;
    @include background("/assets/images/background-coin.svg", 0% 100%);
    width: 100%;
    background-size: contain;
    justify-content: end;
    height: 80vh;
    align-items: center;

    .background-img {
      z-index: 1;
    }

    @media (min-width: 1024px) and (max-width: 1024px) {
      @include background("/assets/images/background-coin.svg");
      width: 100%;
      justify-content: center !important;
      // background-position: bottom -150px right 0px;
      align-items: center;
      height: 100vh;
    }
    @include mobile {
      justify-content: center !important;
      background-position: bottom -150px right 0px;
    }
  }

  .left {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 100px;
    color: white;

    @include mobile {
      display: none;
    }

    .title {
      margin-top: 40px;
      color: black;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 32px;
      line-height: 34px;
      letter-spacing: 1px;
    }

    .line {
      width: 25%;
      height: 5px;
      background-color: white;
      border-radius: 100px;
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .description {
      letter-spacing: 2px;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 34px;
    }

    .logo {
      width: 107px;
    }

    .backWorker {
      width: 100vw;
      height: 100vh;
      background-color: #27bc67;
    }
  }

  .loginInfo {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .backToLogin {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .loginBtn {
      justify-content: center;
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 20px;
    }

    .bottom-info {
      width: 50%;
      display: flex;
    }
  }

  .loginLabel {
    color: black !important;
    .placeholder {
      color: black !important;
    }
  }
  .footer-absolute {
    padding: 20px;
  }
  .forgotPassword {
    font-size: 15px;
    font-weight: 300;
    margin-top: 15px;
    margin-bottom: 2px;
    cursor: pointer;
    color: $primary-color;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    &:hover {
      opacity: 0.3;
    }
  }

  .form {
    background-color: white !important;
    border-radius: 20px;
    flex-direction: column;
    display: flex;

    width: 45%;
    z-index: 10;
    min-height: 578px;
    padding: 10px 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: calc(100vh - 80px);

    .form-scroll {
      display: flex;
      align-items: center;
      flex-direction: column;
      // height: 100%;
      // overflow: auto;
    }

    &.outside {
      height: auto;

      .inputs .generic-input {
        margin-top: 10px !important;
      }
    }

    @include mobile {
      position: fixed !important;
      border-radius: 30px;
      align-items: center;
      text-align: center;
      width: 85%;
      justify-content: center;
      display: flex;
    }

    @include mini-mobile {
      position: fixed !important;
      border-radius: 30px;
      align-items: center;
      text-align: center;
      width: 90%;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      position: absolute;
      width: 85%;
    }

    .logo {
      width: 250px;
      margin-bottom: 5px;

      @include mobile {
        width: 250px;
        margin-bottom: 0px;
      }
    }

    .title {
      color: black;
      font-weight: 700;
      font-size: 25px;
      letter-spacing: 1px;
      margin-bottom: 20px;

      @include mobile {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 22px;
      }
    }

    .line {
      width: 15%;
      height: 5px;
      background-color: $primary-color;
      border-radius: 100px;
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .description {
      background-color: transparent !important;
      font-weight: 400;
      font-size: 18px;
      margin-top: 10px;
      font-size: large;
      text-align: center;
      margin-bottom: 10px;

      @include mobile {
        margin-top: 0px;
        font-size: 16px;
      }
    }

    .login-input {
      width: 100% !important;
      color: black !important;
    }

    .login-select {
      width: 100% !important;
      color: black !important;

      div[class*="-Input"] {
        font-weight: normal;
        font-family: Poppins, sans-serif;
      }

      div[class*="-MenuList"] {
        max-height: 220px !important;
        overflow: auto;
      }

      // div[class*="-ValueContainer"] {
      //   padding: 6px 12px;
      // }

      .css-13cymwt-control {
        color: black;
        background-color: #eff0f6;
        border: 0px !important;
      }
      .css-1jqq78o-placeholder {
        color: $text-label-color;
        font-size: 15px;
        font-weight: normal;
        font-family: Poppins, sans-serif;
      }
      .css-sk63cg-control {
        background-color: #eff0f6;
        border: 0px !important;
        outline: none !important;
      }
      .css-19mg7m3-option {
        background-color: #1967d2;
      }
    }

    .inputs {
      text-align: left;
      width: 100%;
      padding-top: 15px;
      height: calc(100vh - 380px);
      overflow: auto;
      padding-left: 20px;
      padding-right: 20px;
      // @media screen and (max-width: 1450px) {
      //   height: 400px;
      // }
      
      @include mobile {
        height: calc(100vh - 500px);
      }

      > div > div > input {
        color: black !important;
        background-color: #eff0f6;
      }

      .div-icon-eye {
        background: white !important;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        display: flex;
        align-items: center;
        padding: 10px;
        color: black;
        cursor: pointer;
      }

      .generic-input {
        margin-top: 30px !important;
        width: 100% !important;

        &.error {
          border-color: "red";
          background-color: #27bc67;
        }

        .label {
          margin-bottom: 10px;
        }
      }

      .terms {
        display: flex;
        // gap: 25px;
        flex-wrap: wrap;
        .inputCheck {
          width: max-content;
        }

        .terms-a {
          gap: 5px;
          padding-left: 5px;
          // display: flex;
          a {
            color: #000;
          }
        }
      }
    }

    .navButonLog {
      display: flex;
      flex-direction: column;
    }

    .button {
      padding-inline: 40px;
      width: 100% !important;
    }

    .link-button {
      text-decoration: none;
    }
  }

  .backWorker {
    width: 100vw;
    height: 100vh;
  }

  .backBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .backIcon {
    color: $primary-color;
    margin-right: 10px;
    font-size: 26px;
  }

  .form-control {
    color: black !important;
    border: none;
    background-color: #eff0f6;
  }
}

.register-container {
  .title-register {
    color: #000;
    font-weight: 700;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 0px;
  }
}
