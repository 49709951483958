$breakpoint-md: 768px !default;
$breakpoint-xl: 1200px !default;
.packages-container {
    display: flex;
    flex-direction: column;
    gap: 1REM;
    width: 100%;
    height: 100%;
    overflow: auto;
  
    .div-card {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .div-title {
            flex-direction: row;
            display: flex;
            align-items: center;
            color: black;
            justify-content: center;
            font-weight: bold;
        }

        .div-package {
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            display: flex;
            margin-top: 25px;
            padding-top: 15px;
            width: 100%;
            flex-wrap: wrap;     
            height: 100%;
            margin-bottom: 30px;
            overflow: auto;
            max-height: 100vh;
            

            @media (max-width: $breakpoint-xl)  {
                flex-wrap: wrap;
                gap: 20px;
            }

            .package {
                background-color: white;
                width: 300px;
                border-radius: 8px;
                box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.2);
                height: 520px;
                margin-bottom: 20px;
                position: relative;

                .div-img {
                    position: absolute;
                    .range {
                        height: 120px;
                        margin-right: 10px;
                    }
                }

                .img {
                    align-items: center;
                    justify-content: center;
                    display: flex;
                }

                .coin {
                    height: 120px;
                    margin-top: 25px;
                }

                .amount {
                    color: black;
                    font-weight: bold;
                    font-size: 26px;
                    justify-content: center;
                    display: flex;
                }

                .credits {
                    color: black;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    font-weight: 600;
                    margin-bottom: 15px;
                    margin-top: 10px;
                }

                .plan-name {
                    color: #000;
                    font-weight: bold;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    margin-top: 15px;
                }

                .div-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;

                    .icon-custom {
                        // height: 150px;
                        font-size: 100px;
                    }

                }


                .description-container {
                    display: flex;
                    height: 100px;
                    flex-direction: column;
                    justify-content: center;


                    .div-description {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;

                        .value-description {
                            color: #a5a2a5;
                            font-weight: 600;
                        }

                    }
                }

                .pay {
                    display: flex;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: 500;
                    color: #999999;
                }

                .value-one {
                    color: black;
                    font-weight: 700;
                    justify-content: center;
                    display: flex;
                }

                .div-parcelamentos {
                    flex-direction: row;
                    display: flex;
                    justify-content: center;

                    .parcelamento {
                        display: flex;
                        margin-right: 5px;
                        color: black;
                        font-weight: 700;
                    }

                    .value {
                        color: black;
                        font-weight: 700;
                    }
                }

                .div-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;

                    .button {
                        background-color: red;
                        border: none !important;
                        padding: 5px;
                        width: 150px;
                    }
                }

                .div-button-green {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 15px;
                    flex-direction: row;

                    .button {
                        background-color: green;
                        border: none !important;
                        padding: 8px;
                        margin-top: 10px;
                    }
                }
            }

            .line {
                width: 55px;
                height: 100%;
                background-color: red;
            }
        }

    }

}