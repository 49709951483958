.user-screen-container {
  padding: 25px;
  .user-screen {
    margin-top: 10px;
    padding: 20px;
    border-radius: 8px;
    background-color: white;

    .div-table {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column !important;
      padding: 20px;
      overflow: hidden;
      height: calc(100vh - 250px);

      @include mobile {
        padding: 20px 15px;
      }

      .div-titles {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        background-color: #f7f7fc;
        width: 100% !important;
        height: 35px !important;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 8px;

        .title {
          color: black;
          font-size: medium;
          font-weight: 500;
          align-items: center;
          display: flex;
        }
      }
    }

    .sub-title {
      display: flex;
      width: 100%;
      justify-content: flex-start !important;
      margin-bottom: 10px;

      @include mobile {
      }
    }

    .rgt-wrapper {
      border: none !important;
      height: calc(100vh - 250px) !important;
    }

    .rgt-cell {
      justify-content: center;
      > div {
        display: flex;
        justify-content: center;
      }
    }

    .rgt-text-truncate {
      font-weight: 600;
      font-size: 16px;
    }

    .rgt-cell-header-inner-not-pinned-right {
      border: none !important;
      background-color: #f7f7fc;
    }

    .rgt-cell-header-inner {
      border: none !important;
      background-color: #f7f7fc;
      justify-content: space-around;
      padding: 0px;
    }

    .rgt-footer {
      border: none !important;
    }

    .rgt-header-container {
      border: none !important;
    }

    .rgt-cell-header-pinned-right {
      border: none;
    }

    .rgt-cell-header-sticky {
      border: none;
    }

    .rgt-container {
      .rgt-cell-header {
        &:nth-child(1) {
          .rgt-cell-header-inner {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
          }
        }

        // &:nth-child(6) {
        //   .rgt-cell-header-inner {
        //     border-top-right-radius: 8px !important;
        //     border-bottom-right-radius: 8px !important;
        //   }
        // }
        &:nth-child(5) {
          .rgt-cell-header-inner {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
          }
        }
      }
    }

    .title {
      font-size: 16px;
      color: black;
      font-weight: bold;
      height: calc(100vh - 250px);
    }
    .table {
      tr {
        td {
          font-size: 14px;
          padding: 5px 0px 0px 0px;
        }
      }
    }

    .field-actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .react-datepicker-popper {
    z-index: 10000;
  }

  .div-filter-user {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 2rem;
    align-items: center;
    margin-bottom: 20px;
    z-index: 10000;

    .input-row {
      flex-direction: row;
      flex-wrap: nowrap;

      .search-input {
        margin-left: 0px;
        padding-left: 10px;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        height: 45px;
        border-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-family: Poppins, sans-serif;
        font-style: normal;
      }
    }

    .select-size {
      margin-bottom: 0px !important;

      .css-19mg7m3-option {
        color: #000 !important;
        font-family: Poppins, sans-serif;
        font-size: 14px;
      }
      .css-1jqq78o-placeholder {
        color: #a5a8c1 !important;
        font-weight: 600;
        font-family: Poppins, sans-serif;
        font-size: 14px;
      }

      .css-13cymwt-control {
        height: 45px !important;
        border: 0px !important;
        background-color: #eff0f6 !important;
        min-width: 200px;
      }

      .css-sk63cg-control {
        height: 45px !important;
        border: 0px !important;
        background-color: #eff0f6 !important;
        min-width: 200px;
        outline: none !important;
      }
      .css-1nmdiq5-menu {
        background-color: #eff0f6 !important;
        z-index: 999999999 !important;
      }

      .css-qbdosj-Input {
        color: #a5a8c1 !important;
        font-weight: 600;
        font-family: Poppins, sans-serif;
        font-size: 14px;
      }

      .css-g4ngr7-option {
        color: #a5a8c1 !important;
        font-family: Poppins, sans-serif;
        font-size: 14px;
      }

      .css-1qmiu5g-option {
        color: #a5a8c1 !important;
        font-family: Poppins, sans-serif;
        font-size: 14px;
      }
    }
    .filter-button {
      padding: 10px;

      .icon-filter {
        margin-right: 10px;
      }
    }

    .new-button {
      padding: 10px;
      margin-left: 15px;
      background-color: #ff004d;
      border: 0px !important;
      height: 45px;
      border-radius: 4px !important;

      .icon-filter {
        margin-right: 10px;
      }
    }

    .input-size {
      // color: red;
      background-color: #eff0f6;
      border: none !important;
    }

    .input-group {
      height: 45px;
      width: auto;

      input::placeholder {
        color: #a0a3bd !important;
        display: flex;
      }
    }

    .input-group-text {
      height: 45px !important;
      background-color: #eff0f6;
    }

    .search-Icon {
      color: #a0a3bd !important;
    }
  }
}
