.home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f7f7f7;
  overflow: auto;

  @include mobile {
    height: 90%;
  }

  .header {
    padding-left: 10px;

    @include mobile {
      padding: 0px;
    }
  }

  .text-limit {
    display: flex;
    font-weight: bold;
    margin-left: 15px;
    font-size: 22px;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .limit {
    font-weight: bold;
    margin-left: 15px;
    font-size: 38px;
    margin-bottom: 15px;
  }

  .button-header {
    display: flex;
    margin-left: 20px;
    padding: 0px;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    margin-bottom: 10px;
  }

  .payment-button {
    padding: 6px !important;
    width: 45px !important;
    height: 45px !important;
    margin-top: 9px;
    margin-left: 20px;
  }

  .div-dados {
    flex-direction: row;
    display: flex;
    margin-bottom: 20px;
    margin-left: 15px;

    .title-dados {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      margin-right: 20px;

      .dados {
        display: flex;
        font-weight: bold;
        font-size: 20px;
        margin-top: 5px;
        padding-left: 5px;
      }
    }
    .title-dados-green {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      margin-right: 20px;
      color: #35a952;

      .dados {
        display: flex;
        font-weight: bold;
        font-size: 20px;
        margin-top: 5px;
        padding-left: 5px;
        color: black;
      }
    }
  }

  .card-header-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    @include mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    @include tablet {
    }

    .card-header {
      background-color: white;
      overflow: hidden;
      height: 100px;
      margin: 15px;
      margin-right: 18px;
      border: 2px solid;
      border-radius: 8px;
      border-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @include mobile {
        width: 100%;
        overflow: auto;
        justify-content: center;
        overflow: hidden;
      }

      .img-card-header {
        height: 35px;
        margin-bottom: 25px;

        @include mobile {
          height: 10px;
        }
      }

      .img-header {
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .value-card-header {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        font-size: 20px;
        margin-right: 30px;

        @include mobile {
          margin-right: 10px;
          font-size: 14px;
        }
      }

      .connected {
        background-color: #199900 !important;
        color: white;
        width: 140px !important;
        height: 30px !important;
        margin-right: 0px !important;
        border-radius: 8px !important;
        font-size: 12px;
        padding: 12px 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .integration-button {
        background-color: #ff004d !important;
        color: white !important;
        border: none;
        margin: 0px;
      }

      .connect-button {
        background-color: #199900;
        color: white;
        width: 140px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .icon-integration {
        color: white;
        font-size: 20px;
      }
    }
  }

  .line {
    display: flex;
    width: 100%;
    margin-left: 12px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 15px;
    flex-direction: row;
    height: 25px;
  }
}

.title-jornada {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 15px;
  padding-left: 30px;
}

.line {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-left: 15px;

  @include mobile {
    width: 100%;
    margin: 5px;
    margin-left: -15px;
  }
}

.card-container {
  margin-left: 10px;
  flex-direction: row !important;
  display: flex;
  width: 100%;
  margin-top: 5px;
  padding-right: 15px;

  @include mobile {
    width: 100%;
    flex-direction: column !important;
    align-items: center;
    margin-left: 0px;
    overflow: auto;
  }

  .card {
    min-height: 325px;
    margin: 15px;
    width: 100%;
    border-radius: 8px;
    border-color: transparent;
    text-align: center;
    background-color: white;
    padding: 15px;
    justify-content: space-between;

    .title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 5px;
      color: black;
    }

    .div-description {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 15px;
      // font-weight: 500;
      // font-weight: bold;
      font-size: 12px;
      font-family: Poppins, sans-serif;
    }

    .div-lottie {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .ticketButton {
      background-color: #ff004d !important;
      border: none;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .card-icons {
      background-color: #4ffffd;
      color: white;
      height: 30px;
      width: 30px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: large;
      margin-top: 10px;
    }

    .card-icons-check {
      background-color: #6e7191;
      color: white;
      height: 30px;
      width: 30px;
      border-radius: 8px;
    }

    .default-button {
      background-color: transparent !important;
      color: black !important;
      border: none;
    }

    .connect-button {
      background-color: #199900;
      color: white;
      width: 140px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &.color-1 {
        background-color: #199900 !important;
      }

      &.color-2 {
        background-color: #6e7191 !important;
        align-items: center;
        display: flex;
        width: 100%;
        margin: 0px 25px;
      }

      &.color-3 {
        background-color: #6e7191 !important;
        align-items: center;
        display: flex;
      }
    }

    .icon-connect {
      color: white;
    }

    .titles {
      text-align: left;
      margin-top: 5px;

      .subTitle-card {
        margin-top: 15px;
        margin-bottom: 15px;
        flex-direction: row;
        display: flex;
        justify-content: center;

        .description {
          font-weight: bold;
          padding-left: 5px;
        }
      }
    }

    .card-icon-bottom {
      margin-bottom: 10px;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #dadada;
      border-radius: 8px;

      &.send {
        background-color: #6e7191;
      }

      .icon-bottom {
        display: flex;
        width: 35px;
        height: 30px;
        align-items: center;
        border-radius: 8px;
        justify-content: center;
        margin-right: 10px;
        color: white;

        .export {
          color: white;
        }
      }

      .sheet {
        color: white;
        width: 35px;
      }
    }

    .div-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 35px;
    }
  }
}

.icon {
  height: 32px;
}

.icon-header {
  height: 32px;
  width: 32px;
  color: #f7f7f7 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
