.div-details-plan-container {
  padding: 20px;
  overflow-y: visible;

  @include mobile {
    padding: 20px 15px;
  }

  .sub-title {
    display: flex;
    width: 100%;
    justify-content: flex-start !important;
    margin-bottom: 10px;

    @include mobile {
    }
  }
.details-title{
  margin-bottom: 1rem;

}

.ontainer{
  max-height: 400px;
    overflow: auto;
    padding: 0px 10px;
    flex-direction: column;

   

  .packages-div{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
    align-items: center;
    .text-package{
      font-weight: 600;

    }

    .div-checks{
      >text{
        font-size: 14px;
      }
    }
    >text{
      font-size: 14px;
    }

  }
}
  .inputs {
    text-align: left;
    width: 100%;
    margin-top: 15px;
    margin: 20px 0px;
    border: 0px !important;

    @include mobile {
     // height: calc(100vh - 380px);
      overflow: auto;
      padding: 15px;
    }

    > div > div > input {
      // color: black !important;
      background-color: #eff0f6;
      border: 0px !important;
    }

    .div-icon-eye {
      background: white !important;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      display: flex;
      align-items: center;
      padding: 10px;
      color: black;
      cursor: pointer;
      border: 0px !important;
    }

    .generic-input {
      margin-top: 30px !important;
      width: 100% !important;
      border: 0px !important;

      &.error {
        border-color: "red";
        background-color: #27bc67;
      }

      .label {
        margin-bottom: 10px;
      }
    }

    .default-input {
      border: 1px solid #eff0f6 !important;
      border-radius: 4px;
      background-color: #eff0f6;
    }
  }

  .div-icon {
    background-color: #eff0f6;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 40px;
    width: 45px;
    border-radius: 8px;
    margin-left: 10px;

    @include mobile {
      margin-left: 15px;
      height: 39px;
    }

    .icon {
      font-size: 16px;
      color: white;

      @include mobile {
        font-size: 16px;
      }
    }
  }
.documents-input{

  >div{
    >input{
      height: 40px;
    }
  }
 
}
  .documents-select {
    width: 100% !important;
    color: black !important;
    height: 40px;

    div[class*="-Input"] {
      font-weight: normal;
      font-family: Poppins, sans-serif;
    }

    div[class*="-ValueContainer"] {
      padding: 6px 12px;
    }

    .css-13cymwt-control {
      color: black;
      background-color: #eff0f6;
      border: 0px !important;
    }

    .css-sk63cg-control{
height: 40px;
    }

    .css-1jqq78o-placeholder {
      color: $text-label-color;
      font-size: 15px;
      font-weight: normal;
      font-family: Poppins, sans-serif;
    }

    .css-sk63cg-control {
      background-color: #eff0f6;
      border: 0px !important;
      outline: none !important;
    }

    .css-19mg7m3-option {
      background-color: #1967d2;
    }
    .css-16xfy0z-control{
      height: 40px;
      flex-wrap: nowrap;
    }
  }
  }
  .css-qbdosj-Input{
  padding: 0px !important;
  }