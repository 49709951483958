.repurcharse-container {
    height: 100vh;
    margin: 20px;
    padding-left: 10px;

    .card-payment {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .titles {
            font-weight: 500;
            font-size: 22px;
        }

        .div-input {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            justify-content: center;
            align-items: center;

            .label-input {
                color: black;
            }

            .div-button {
                margin-top: 15px;

                .button {
                    border: none !important;
                    border-radius: 8px;
                    background-color:#d9dbe9 ;
                    font-weight: 600;
                    color: black;
                }
            }
        }
    }

    .card-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .titles {
            font-weight: 500;
            font-size: 22px;
        }

        .css-13cymwt-control {
            border: solid transparent !important;
            width: 250px;
        }

        .css-sk63g-control:hover {
            border: solid transparent !important;
            width: 250px;
        }

        .label-select {
            color: black,
        }

    }

    .form-control {
        margin-right: 10px;
    }
}