
.ModalMobile{
    display: flex;
    flex-direction: column;
    
    

    .infosMobile{
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 10px ;
        border-bottom: 1px solid #b8b7b7;
    }
    .eyeMobile{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 5px;
    }
  
}



.button {
    border-radius: 8px;
    padding: 12px 10px;
    color: white;
    background-color:$primary-color ;
    cursor: pointer;
    
    &.btn-save-details{
        margin-right: 10px;
        padding: 5px 25px;
        background-color: #199900;
        border:0px;

        &:hover {
            color: white;
            background-color: #199900 !important;
           
    
            &.dark {
                background-color: #199900 !important;
            }
        }
    }

    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin: 0px 10px;

       
    }

    &.dark {
        background-color: $primary-color;
    }

    &.secondary {
        background-color: $secondary-color;
    }

    &:hover {
        color: white;
        background-color:$primary-color;
       

        &.dark {
            background-color: $primary-color;
        }
    }

    /* Outline, ou seja, apenas borda */
    &.outline {
        border-width: 2px;
        background-color: transparent;

        &.primary {
            border-color: $primary-color;
            color: $primary-color;
        }

        &.dark {
            border-color: $primary-color;
            color: $primary-color;
        }

        &.background {
            border-color: $primary-color;
            color: $primary-color;
            background-color: $primary-color;
        }

        &:hover {
            color: white;
            background-color: $primary-color;

            &.dark {
                background-color: $primary-color;
            }
        }
    }
}

.link-button {
    border-radius: 8px;
    padding: 0px;
    color: $primary-color;
    background-color: transparent !important;
    cursor: pointer;

    &.block{
        cursor: no-drop !important;
    }

    span {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
    }

    &.dark {
        color: $primary-color;
    }

    &:hover {
        color: $primary-color;

        &.dark {
            color: $primary-color;
        }
    }

    &:focus {
        border: none;
        box-shadow: none;
    }
}

.btn {
    box-shadow: none !important;
}