.calendar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
        display: flex;
        align-items: center;
        color: black;
        margin-bottom: 15px;
        font-weight: 600;
    }

    .sub-title {
        display: flex;
        align-items: center;
        font-weight: 600;
    }

    .div-calendar {
        width: 100%;
        height: 100%;
        margin-bottom: 15px;


        .calendar {
            height: 100%;
            flex-direction: row;
            display: flex;
        }

        >div {
            >iframe {
                // background-color: blue;
                height: 100%;

                ._cUP1np9gMvFQrcPftuf {
                    .xahN8AEzyAvQtVj17TPv {
                        margin: 0px;
                    }
                }
            }
        }

    }

    .div-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 15px;

        .default-button {
            display: flex;
            justify-content: flex-end;
            padding: 8px 10px;
        }
    }

}