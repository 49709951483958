.home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f7f7f7;
  overflow: auto !important;
  padding-bottom: 20px;

  @include mobile {
    height: 90%;
  }

  .header {
    padding-left: 10px;

    .header-info{
      display: flex;
      flex-wrap: wrap;
    }

    @include mobile {
      padding: 0px;
    }
  }

  .text-limit {
    
    display: flex;
    font-weight: bold;
    margin-left: 15px;
    font-size: 26px;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .limit {
    font-weight: bold;
    margin-left: 15px;
    font-size: 38px;
    margin-bottom: 15px;
    color: #a0a3bd;
  }

  .card-header-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;

    .blocked {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;

      background-color: #828282;
      opacity: 0.7;
      border-radius: 8px;
      align-items: center;
    display: flex;
    justify-content: center;

      svg {

        font-size: 60px;
      }
    }
    @include mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    @include tablet {
    }

    .card-header {
      background-color: white;
      overflow: hidden;
      height: 100px;
      margin: 15px;
      margin-right: 18px;
      border: 2px solid;
      border-radius: 8px;
      border-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @include mobile {
        width: 100%;
        overflow: auto;
        justify-content: center;
        overflow: hidden;
      }

      .img-card-header {
        height: 35px;
        margin-bottom: 25px;

        @include mobile {
          height: 10px;
        }
      }

      .img-header {
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .title-card-header {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        font-size: 20px;
        margin-right: 30px;

        @include mobile {
          margin-right: 10px;
          font-size: 14px;
        }

        .subTitle-card-header {
          display: flex;
          flex-direction: column;
          color: #dadada;
          font-size: 10px;
        }
      }

      .integration-button {
        background-color: #ff004d !important;
        color: white !important;
        border: none;
        margin: 0px;
      }

      .connect-button {
        background-color: #199900;
        color: white;
        width: 140px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .icon-integration {
        color: white;
        font-size: 20px;
      }
    }
  }
}

.title-jornada {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 15px;
  padding-left: 30px;
}
.stepper {
}

.line {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-left: 15px;

  @include mobile {
    width: 100%;
    margin: 5px;
    margin-left: -15px;
  }
}

.card-container {
  margin-left: 10px;
  flex-direction: row !important;
  display: flex;
  width: 100%;
  margin-top: 5px;
  padding-right: 15px;

  @include mobile {
    width: 100%;
    flex-direction: column !important;
    align-items: center;
    margin-left: 0px;
    overflow: auto;
    padding-right: 0;
  }

  .card {
    min-height: 325px;
    margin: 15px;
    width: 100%;
    border-radius: 8px;
    border-color: transparent;
    text-align: center;
    background-color: white;
    align-items: center;
    padding: 15px;
    position: relative;

   
    .checked {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;

      background-color: #ecf5e6;
      opacity: 0.7;
      border-radius: 8px;

      svg {
        margin-top: 70px;
        font-size: 160px;
      }
    }
    .blocked {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;

      background-color: #4d4d4d;
      opacity: 0.7;
      border-radius: 8px;

      svg {
        margin-top: 70px;
        font-size: 160px;
      }
    }

    @include mobile {
      width: 95%;
      margin: 5px;
      overflow: auto;
      margin-right: 0;
    }

    .title {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 15px;
      color: black;
    }

    .title-C {
      font-weight: 500;
      font-size: 22px;
      margin-bottom: 15px;
      color: #6e7191;
    }

    .card-icons {
      background-color: #199900;
      color: white;
      height: 30px;
      width: 30px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: large;
      margin-top: 10px;
    }

    .card-icons-check {
      background-color: #6e7191;
      color: white;
      height: 30px;
      width: 30px;
      border-radius: 8px;
    }

    .default-button {
      background-color: transparent !important;
      color: black !important;
      border: none;
    }

    .connect-button {
      background-color: #199900;
      color: white;
      width: 140px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &.color-1 {
        background-color: #199900 !important;
      }

      &.color-2 {
        background-color: #6e7191 !important;
        align-items: center;
        display: flex;
        width: 100%;
        margin: 0px 25px;
      }

      &.color-3 {
        background-color: #6e7191 !important;
        align-items: center;
        display: flex;
      }
    }

    .icon-connect {
      color: white;
    }

    .titles-C {
      text-align: center;
      margin-top: 5px;
      color: #6e7191;

      .subTitle-card-C {
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 15px;
        color: #6e7191;
      }
    }

    .titles {
      text-align: center;
      margin-top: 5px;

      .subTitle-card {
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }

    .card-icon-bottom {
      margin-bottom: 10px;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #dadada;
      border-radius: 8px;

      &.send {
        background-color: #6e7191;
      }

      .icon-bottom {
        display: flex;
        width: 35px;
        height: 30px;
        align-items: center;
        border-radius: 8px;
        justify-content: center;
        margin-right: 10px;
        color: white;

        .export {
          color: white;
        }
      }

      .sheet {
        color: white;
        width: 35px;
      }
    }

    .div-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 35px;
    }
  }
}

.icon {
  height: 32px;
}
