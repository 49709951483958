.card-table {
    width: 100%;

    .table> :not(:first-child) {
        border: none !important;
    }

    .table> :not(caption)>*>* {
        border: none !important;
    }
  
    .not-result-center{
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

.list {
    .name-text-list{
        text-transform: capitalize;
    }
}