.documents-container {
  display: flex;
  flex-direction: column;
  // height: 100%;
  background-color: #f7f7f7;
  overflow: hidden;
  padding: 25px;
  align-items: center;
  
  .packages {
    padding: 20px;

    .packages-container {
      padding-top: 10px;
    }

    .packages-div {
      background-color: #f2f2f2;
      color: #9a9a9a;
      padding: 20px;
      border-radius: 8px;
    }

    .package-title {
      display: flex;
      flex-direction: row;
      
      text {
        font-weight: bold;
        flex: 1
      }

    }
  }

  .details-plan-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
    > div {
      flex: 1;
    }
  }

  @include mobile {
    margin-bottom: 50px !important;
    padding-top: 10px;
  }

  .file-container-item {
    .generic-input {
      width: 100%;
    }
    display: flex;
    flex-direction: row;
  }

  .dropzone-container {
    .dropzone {
      align-items: flex-start;
      justify-content: flex-start;

      > p {
        text-align: left;
      }
    }
  }

  .item-disabled {
    input, .input-group, .default-input {
      background-color: #f2f2f2 !important;
      color: hsl(0, 0%, 60%) !important;

      &::placeholder {
        color: hsl(0, 0%, 60%) !important;
      }
    }
  }

  .tabs {
    background-color: #d9dbe9;
    color: black !important;
    height: 50px;
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: 8px 8px 0px 0px !important;
    font-weight: 400;
    cursor: pointer;
  }

  .react-tabs{
    width: 100%;
  }

  .react-tabs__tab--selected {
    background-color: white !important;
    border-radius: 8px 8px 0px 0px !important;
    padding: 15px;
    display: flex;
    align-items: center;
  }

  .tab-panel {
    background-color: white;

    .documents-input {
      width: 100% !important;
      color: black !important;

      .form-control {
        background-color: #eff0f6;
        border: none !important;
      }
    }

    .div-documents {
      width: 100%;
      // max-height: calc(100vh - 280px);
      // overflow: auto;

      @include mobile {
        // max-height: 240px;
        height: calc(100vh - 380px);
        overflow: auto;
        padding: 15px;
      }

      .input-container {
        width: 100%;
        flex-direction: row;
        display: flex;
        align-items: center;

        .input {
          background-color: #eff0f6;
          width: 100% !important;
          // height: 80px;
          border-radius: 8px;
          margin-bottom: 15px;
          margin-top: 5px;
          justify-content: space-between;
          flex-direction: column;
          display: flex;
          padding: 15px;
        }

        .title-input {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .input-bottom {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          @include mobile {
            flex-direction: column;
          }

          .process {
            display: flex;
            flex-direction: row;
            align-items: center;

            @include mobile {
            }

            .subtitle-input {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 10px;
              margin-right: 10px;
            }
          }

          .button-documents {
            border: none;

            @include mobile {
              height: 60px;
              margin-top: 5px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

    .div-button {
      flex-direction: row;
      display: flex;
      width: 100%;
      justify-content: flex-end;

      @include mobile {
        margin-top: 10px;
      }

      .button-cancel {
        background-color: transparent;
        color: black;
        margin-right: 5px;
      }

      .button-send {
        background-color: #27bc67;
        border: none;
        margin-left: 5px;
      }
    }
  }
}

.css-sk63cg-control{
  border: 0px !important;
  outline: none !important;
}