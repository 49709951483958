.form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
    background-color: $primary-color !important;
    border-color: $primary-color !important;
}

.form-switch {
    @include mobile {
        margin-bottom: 80px !important;
    }
}

.switch-label {
    margin-bottom: 0px;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 16px;
}