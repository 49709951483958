
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html {
    font-family: "Poppins", sans-serif;
}
@supports (font-variation-settings: normal) {
    html {
        font-family: "Poppins var", sans-serif;
    }
}

// Heading

h1 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
}

h2 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
}

h3 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
}

h4 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
}

h5 {
    font-family: Poppins, sans-serif, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
}

h6 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}



// text

small {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
}

span {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
}

span.caption {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
}

p {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    font-weight: 400;
}

label {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

input {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    text-overflow: ellipsis;
}
